import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Directory from "../../components/directory/directory"

const Message = ({ message, meta }) => {
  return (
    <>
      <Helmet
        title={`Ummo Message`}
        titleTemplate={`%s | ${meta.title}`}
        meta={[
          {
            name: `description`,
            content: `We know that only a small minority vaguely senses the possibility that beings similar to you and from other stars in this Galaxy will be secretly among you..
                        We know that only a negligible fraction of these people scattered throughout this planet`,
          },
          {
            property: `og:title`,
            content: `${meta.title}`,
          },
          {
            property: `og:description`,
            content: `We know that only a small minority vaguely senses the possibility that beings similar to you and from other stars in this Galaxy will be secretly among you..
                        We know that only a negligible fraction of these people scattered throughout this planet`,
          },
          {
            property: `og:url`,
            content: `${meta.url}/ummo-oummo/message`,
          },
          {
            property: `og:type`,
            content: `article`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            property: "og:image",
            content: `${message.featuredImage.url}`,
          },
          {
            name: `twitter:creator`,
            content: `${meta.author}`,
          },
          {
            name: `twitter:title`,
            content: `${meta.title}`,
          },
          {
            name: `twitter:description`,
            content: `We know that only a small minority vaguely senses the possibility that beings similar to you and from other stars in this Galaxy will be secretly among you..
                        We know that only a negligible fraction of these people scattered throughout this planet`,
          },
        ].concat(meta)}
      />
      <Layout>
        <div className="w-full flex justify-center items-center bg-white py-24">
          <article className="w-2/5 flex justify-center items-center">
            <GatsbyImage
              image={
                message.featuredImage.localFile.childImageSharp.gatsbyImageData
              }
            />
            <div
              dangerouslySetInnerHTML={{ __html: message.content.html }}
              className="prose 4k:text-lg text-stone-600 font-roboto prose-green max-w-none selection:bg-green-200 pb-6 
                          prose-blockquote:border prose-blockquote:border-blue-600 prose-blockquote:bg-blue-600 prose-blockquote:text-slate-100
                          prose-blockquote:py-4 prose-blockquote:text-xl prose-blockquote:shadow-lg prose-blockquote:rounded prose-blockquote:text-center"
            />
          </article>
        </div>
        <Directory />
      </Layout>
    </>
  )
}

const UmmoMessage = ({ data }) => {
  const messageContent = data.graphCmsMessage
  const meta = data.site.siteMetadata
  if (!messageContent) return null
  return <Message message={messageContent} meta={meta} />
}

export default UmmoMessage

Message.propTypes = {
  message: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query UmmoMessage {
    graphCmsMessage(id: { eq: "Message:cke4wx08o1idh0117xgcl782n:PUBLISHED" }) {
      id
      featuredImage {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              avifOptions: { lossless: true }
              placeholder: BLURRED
              width: 100
            )
          }
        }
      }
      content {
        html
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        image
        url
      }
    }
  }
`
